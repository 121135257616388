@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.viewportElement {
  margin: 0 auto;
}

.ViewportOverlay {
color: yellow !important;
}

.hideSlider > .viewport-wrapper > .scroll {
  display: none;
}

.ViewportOrientationMarkers {
  display: none;
}

.slick-slide:not(.slick-current) {
  padding-left: 10px;
}

@media (max-width: 500px) {
  .ViewportOverlay {
    color: yellow !important;
    font-size: 10px;
  }
}

/* .image-canvas-wrapper {
  width: 500px;
  height: 325px;
  margin: 0 auto;
}
.image-canvas {
  width: 100%;
  height: 100%;
} */

@media (max-width: 1023px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px !important;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: theme('colors.primary');
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@layer base {
 html {
  @apply font-heading;
 }
}


